<template>
    <div>
        <div v-if="!this.getFlapDetectionEnabled">
            <small>
                {{$t('forms.flap-detection-enable-description')}}
            </small>
            <br>
            <b-button variant="success" class="w-100" v-on:click="enableFlapDetection">{{ $t('action-items.flap-detection-enable') }}</b-button>
        </div>

        <div v-if="this.getFlapDetectionEnabled">
            <small>
                {{$t('forms.flap-detection-disable-description')}}
            </small>
            <br>
            <b-button variant="success" class="w-100" v-on:click="disableFlapDetection">{{ $t('action-items.flap-detection-disable') }}
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "HostFlapdetection",
    props: [
        'hostuuid',
        'flapDetectionEnabled'
    ],
    methods: {
        enableFlapDetection: function(){
            let externalCommand = [
                {
                    command: 'ENABLE_HOST_FLAP_DETECTION',
                    hostUuid: this.getUuid,
                }
            ];
            //externalCommand = JSON.stringify(Object.assign({}, externalCommand));
            this.sendCommand(externalCommand);
        },
        disableFlapDetection: function(){
            let externalCommand = [
                {
                    command: 'DISABLE_HOST_FLAP_DETECTION',
                    hostUuid: this.getUuid,
                }
            ];
            //externalCommand = JSON.stringify(Object.assign({}, externalCommand));
            this.sendCommand(externalCommand);
        },
        sendCommand: function(externalCommand){

           /* axios.post('/hosts/external_commands.json', null, {
                params: {
                    externalCommand: externalCommand,
                }
            }) */
            axios.post('/hosts/external_commands.json', externalCommand)
                .then(response => {
                        this.$notify({
                            type: 'success',
                            title: '',
                            text: this.$t('Command successfully sent'),
                            duration: 7000
                        });
                    this.$emit('refresh', 'flapDetect')
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        },
    },
    computed: {
        getUuid: function(){
            return this.hostuuid;
        },
        getFlapDetectionEnabled: function(){
            return this.flapDetectionEnabled;
        }
    }
}
</script>

<style scoped>

</style>
