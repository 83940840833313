<template>
    <div ref="loader">
        <div class="w-100" v-bind:class="mainHeaderBgClasses">
            <b-container class="container-lg">

                <!-- Hostname and refresh button -->
                <b-row class="p-0 pt-2">
                    <b-col class="col-9 text-left min-width-0">
                        <h5 class="text-truncate">
                            <router-link :to="{name: 'hostsIndex', params: {}}"
                                         class="text-decoration-none">
                                <i class="fa-solid fa-chevron-left"></i>
                            </router-link>
                            {{ mergedHost.name }}
                        </h5>
                    </b-col>
                    <b-col class="col-3 text-right">
                        <button type="button" class="btn btn-sm" @click="refreshData"
                                v-bind:class="mainHeaderRefreshBtnClasses">
                            <i class="fa-solid fa-arrows-rotate"
                               :class="{'fa-spin': loading}"></i>
                        </button>
                    </b-col>
                </b-row>

                <!-- Not in monitoring text -->
                <b-row class="p-0 pt-2 pb-2" v-if="hoststatus.isInMonitoring === false">
                    <b-col class="col-12">
                        <i class="fa-solid fa-triangle-exclamation mr-1"></i>
                        {{ $t('Not-in-monitoring') }}
                    </b-col>
                </b-row>

                <!-- time information -->
                <b-row class="p-0 pt-2 pb-2" v-if="hoststatus.isInMonitoring">
                    <b-col class="col-12">
                        <i class="fa-solid fa-clock-rotate-left mr-1"></i>
                        {{ $t('state-since') }}:
                        <span v-b-popover.click.blur.bottom="hoststatus.last_state_change_user">
                        {{ hoststatus.last_state_change }}
                    </span>
                    </b-col>
                    <b-col class="col-12">
                        <!-- width: 16 to make all icons the same size -->
                        <i class="fa-solid fa-arrow-turn-up fa-rotate-270 mr-1" style="width:16px;"></i>
                        {{ $t('last-check') }}:
                        <span v-b-popover.click.blur.bottom="hoststatus.lastCheckUser">
                        {{ hoststatus.lastCheck }}
                    </span>
                    </b-col>
                    <b-col class="col-12">
                        <i class="fa-solid fa-arrow-turn-down fa-rotate-270 mr-1" style="width:16px;"></i>
                        {{ $t('next-check') }}:
                        <span
                            v-show="mergedHost.active_checks_enabled && mergedHost.is_satellite_host === false">
                            <span v-b-popover.click.blur.bottom="hoststatus.nextCheckUser">
                                {{ hoststatus.nextCheck }}
                            </span>
                    </span>
                        <span
                            v-show="!mergedHost.active_checks_enabled || mergedHost.is_satellite_host === true">
                            {{ $t('n/a') }}
                    </span>
                    </b-col>
                </b-row>

                <!-- icons -->
                <b-row class="p-0 pt-2 pb-2">
                    <b-col class="text-left">
                        <div class="icon-group" role="group">
                            <div class="icon">
                                <priority :priority="mergedHost.priority" :as-bar="true"></priority>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="text-right" v-if="hoststatus.isInMonitoring">
                        <div class="icon-group" role="group">
                            <div class="icon">
                                <i v-show="acknowledgement.is_sticky === false && hoststatus.problemHasBeenAcknowledged === true"
                                   v-b-popover.click.blur.left="$t('Acknowledged')"
                                   class="icon fa-regular fa-user"></i>

                                <i v-show="acknowledgement.is_sticky === true && hoststatus.problemHasBeenAcknowledged === true"
                                   v-b-popover.click.blur.left="$t('Sticky Acknowledgement')"
                                   class="icon fa fa-user"></i>

                                <i class="icon fa-solid fa-power-off"
                                   v-b-popover.click.blur.left="$t('in-downtime')"
                                   v-show="hoststatus.scheduledDowntimeDepth > 0"></i>

                                <div class="icon icon-stack"
                                     v-b-popover.click.blur.left="$t('notifications-enabled')"
                                     v-show="hoststatus.notifications_enabled">
                                    <i class="fas fa-envelope"></i>
                                    <i class="fas fa-check opacity-100 fa-xs text-success cornered cornered-lr"></i>
                                </div>

                                <div class="icon icon-stack"
                                     v-b-popover.click.blur.left="$t('notifications-disabled')"
                                     v-show="!hoststatus.notifications_enabled">
                                    <i class="fas fa-envelope"></i>
                                    <i class="fas fa-times opacity-100 fa-xs text-danger cornered cornered-lr"></i>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>

            </b-container>
        </div>

        <b-container class="container-lg mt-0 p-0 pt-4">
            <b-list-group>
                <b-card no-body class="details-tabs card-body p-0">
                    <b-tabs card pills fill active-nav-item-class="active shadow-primary">

                        <b-tab ref="tab1" active>
                            <template #title>
                                <i class="fa-solid fa-info"></i>
                                {{ $t('Details') }}
                            </template>

                            <div v-if="mergedHost.disabled" class="alert alert-warning-shadow" role="alert">
                                {{ $t('attention') }}
                                {{ $t('currently-disabled', {obj: 'host'}) }}
                            </div>


                            <div class="alert alert-warning-shadow" role="alert" v-show="hasParentHostProblems">
                                <i class="fa fa-exclamation-triangle"></i>
                                {{ $t('Problem with parent host detected!') }}
                                <ul>
                                    <li v-for="parentHostProblem in parentHostProblems"
                                        v-bind:key="parentHostProblem.id">
                                        <router-link :to="{name: 'hostsDetails', params: {id: parentHostProblem.id}}">
                                            {{ parentHostProblem.name }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="!hoststatus.isInMonitoring"
                                 class="w-100 border-primary text-primary shadow-primary p-2 rounded text-center">
                                {{ $t('This host is currently not in monitoring.') }}
                            </div>

                            <div v-if="hoststatus.isInMonitoring">
                                <!-- Downtime -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom"
                                    v-if="hoststatus.scheduledDowntimeDepth > 0">
                                    <div class="mb-3">
                                        <p class="mb-1 overflow-auto">
                                            <i class="fa-solid fa-power-off"></i>
                                            {{ $t('text.maintenance-period', {object: 'Host'}) }}
                                        </p>
                                        <small class="mb-2 overflow-auto"
                                               v-html="$t('text.downtime-set',{owner: downtime.authorName, duration: downtime.durationHuman, start: downtime.scheduledStartTime, end: downtime.scheduledEndTime,  comment: downtime.commentData})">
                                        </small>
                                    </div>
                                </b-list-group-item>

                                <!-- Acknowledged -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom"
                                    v-if="hoststatus.problemHasBeenAcknowledged">
                                    <div>
                                        <p class="mb-1 overflow-auto">
                                            <i v-show="!acknowledgement.is_sticky"
                                               class="fa-regular fa-user"
                                               v-b-popover.click.blur="$t('Acknowledged')"></i>
                                            <i v-show="acknowledgement.is_sticky"
                                               class="fa-solid fa-user"
                                               v-b-popover.click.blur="$t('Sticky Acknowledgement')"></i>

                                            {{ $t('text.state-is-acknowledged', {object: 'Host'}) }}
                                            <span v-if="acknowledgement.is_sticky">
                                                ({{ $t('forms.sticky') }})
                                            </span>
                                        </p>
                                        <small class="mb-1 overflow-auto"
                                               v-html="$t('text.acknowledgement-set',{owner: acknowledgement.author_name, time: acknowledgement.entry_time, comment: acknowledgement.commentDataHtml})">
                                        </small>
                                    </div>
                                </b-list-group-item>

                                <!-- General state information -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom">
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('state') }}
                                        </small>
                                        <h5 class="mb-1" v-bind:class="hoststatus.humanState">
                                            {{ $t(hoststatus.humanState) }}
                                        </h5>
                                    </div>

                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('state-type') }}
                                        </small>
                                        <small class="mb-1" v-if="hoststatus.isHardstate">{{ $t('hard-state') }}
                                            ({{ hoststatus.current_check_attempt }}/{{
                                                hoststatus.max_check_attempts
                                            }})
                                        </small>
                                        <small class="mb-1" v-if="!hoststatus.isHardstate">{{ $t('soft-state') }}
                                            ({{ hoststatus.current_check_attempt }}/{{
                                                hoststatus.max_check_attempts
                                            }})
                                        </small>
                                    </div>

                                    <div class="d-flex w-100 justify-content-between"
                                         v-show="hoststatus.currentState > 0">
                                        <small>
                                            {{ $t('last-time') }} <span class="badge badge-success bg-up">
                                            {{ $t('up') }}
                                        </span>
                                        </small>
                                        <small>
                                            {{ hoststatus.last_time_up }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('flap-detection-enabled') }}
                                        </small>
                                        <small>
                                <span class="badge badge-success bg-up"
                                      v-if="hoststatus.flap_detection_enabled">
                                    {{ $t('yes') }}
                                </span>
                                            <span class="badge badge-danger bg-down"
                                                  v-if="!hoststatus.flap_detection_enabled">
                                    {{ $t('no') }}
                                </span>
                                        </small>
                                    </div>

                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('check-period') }}
                                        </small>
                                        <small>
                                            {{ checkPeriod.name }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('check-interval') }}
                                        </small>
                                        <small>
                                            {{ mergedHost.checkIntervalHuman }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('retry-interval') }}
                                        </small>
                                        <small>
                                            {{ mergedHost.retryIntervalHuman }}
                                        </small>
                                    </div>
                                </b-list-group-item>

                                <!-- Output and Plugin -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none border-bottom">
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('Address') }}
                                        </small>
                                        <small>
                                            {{ mergedHost.address }}
                                        </small>
                                    </div>

                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('commandline') }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between code-block output-style">
                                        {{ mergedHost.hostCommandLine }}
                                    </div>

                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('output') }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between code-block output-style">
                                        {{ hoststatus.output }}
                                    </div>
                                </b-list-group-item>

                                <!-- Notification option -->
                                <b-list-group-item
                                    class="list-group-item-details p-0 pt-1 pb-1 border-none">
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('notification-period') }}
                                        </small>
                                        <small>
                                            {{ notifyPeriod.name }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('notification-interval') }}
                                        </small>
                                        <small>
                                            {{ mergedHost.notificationIntervalHuman }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('notifications-enabled') }}
                                        </small>
                                        <small>
                                <span class="badge badge-success bg-up"
                                      v-if="hoststatus.notifications_enabled">
                                    {{ $t('yes') }}
                                </span>
                                            <span class="badge badge-danger bg-down"
                                                  v-if="!hoststatus.notifications_enabled">
                                    {{ $t('no') }}
                                </span>
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>
                                            {{ $t('notify-on') }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100 justify-content between">
                                        <small class="ml-auto">
                                            <span class="badge badge-success bg-up"
                                                  v-if="mergedHost.notify_on_recovery">
                                                {{ $t('recover') }}
                                            </span>
                                            <span class="badge badge-danger bg-down"
                                                  v-if="mergedHost.notify_on_down">
                                                    {{ $t('down') }}
                                            </span>
                                            <span class="badge badge-secondary bg-unknown"
                                                  v-if="mergedHost.notify_on_unreachable">
                                                    {{ $t('unreachable') }}
                                            </span>
                                            <span class="badge badge-primary"
                                                  v-if="mergedHost.notify_on_downtime">
                                                    {{ $t('downtime') }}
                                            </span>
                                            <span class="badge badge-primary"
                                                  v-if="mergedHost.notify_on_flapping">
                                                    {{ $t('flapping') }}
                                            </span>
                                        </small>
                                    </div>
                                </b-list-group-item>
                            </div>
                        </b-tab>
                        <b-tab ref="tab2">
                            <template #title>
                                <i class="fa-solid fa-cogs"></i>
                                {{ $t('Services') }}
                            </template>

                            <!-- start service list -->
                            <b-list-group
                                v-for="service in services"
                                v-bind:key="service.Service.id"
                                class="mt-2">

                                <div class="flex-column align-items-start">
                                    <div
                                        class="list-group-item flex-column align-items-start list-group-item-action pl-0">

                                        <div class="d-flex flex-row min-h-110">
                                            <div class="p-2">
                                                <Servicestatus v-bind:current_state="service.Servicestatus.currentState"
                                                               v-bind:human_state="service.Servicestatus.humanState"></Servicestatus>
                                            </div>
                                            <div class="min-width-0">
                                                <div class="row p-2">
                                                    <div class="col-12 text-truncate h5">
                                                        <router-link
                                                            :to="{name: 'servicesDetails', params: {id: service.Service.id}}">
                                                            {{ service.Service.servicename }}
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 text-right">
                                                        <div class="icon-group" role="group">
                                                            <div class="icon">
                                                                <priority
                                                                    :priority="service.Service.priority"></priority>

                                                                <i v-show="service.Servicestatus.acknowledgement_type === 1 && service.Servicestatus.problemHasBeenAcknowledged === true"
                                                                   class="icon fa-regular fa-user"></i>

                                                                <i v-show="service.Servicestatus.acknowledgement_type === 2 && service.Servicestatus.problemHasBeenAcknowledged === true"
                                                                   class="icon fa fa-user"></i>

                                                                <i class="icon fa-solid fa-power-off"
                                                                   v-show="service.Servicestatus.scheduledDowntimeDepth > 0"></i>

                                                                <div class="icon icon-stack"
                                                                     v-show="service.Servicestatus.notifications_enabled">
                                                                    <i class="fas fa-envelope"></i>
                                                                    <i class="fas fa-check opacity-100 fa-xs text-success cornered cornered-lr"></i>
                                                                </div>

                                                                <div class="icon icon-stack"
                                                                     v-show="!service.Servicestatus.notifications_enabled">
                                                                    <i class="fas fa-envelope"></i>
                                                                    <i class="fas fa-times opacity-100 fa-xs text-danger cornered cornered-lr"></i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <date-switch v-bind:css_class="service.Servicestatus.humanState"
                                                                     v-bind:status="service.Servicestatus"></date-switch>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </b-list-group>
                            <!-- end service list -->

                            <b-list-group class="mt-3" ref="noMass">
                                <div v-if="services.length > 0">
                                    <div class="d-flex w-100 justify-content-between">
                                        <small>{{ $t('page') }} {{ services_paging.page }}</small>
                                        <small>{{ $t('pagesize') }} {{ services_paging.limit }}</small>
                                    </div>
                                    <div class="d-flex w-100 justify-content-center mt-4">
                                        <sliding-pagination
                                            :current="services_paging.page"
                                            :total="services_paging.pageCount"
                                            @page-change="getServiceData">
                                        </sliding-pagination>
                                    </div>
                                </div>
                                <b-list-group-item v-if="services.length === 0">
                                    <h5 class="mb-1">{{ $t('noitems') }}</h5>
                                </b-list-group-item>
                            </b-list-group>
                        </b-tab>

                        <b-tab ref="tab3"
                               v-if="hostDetails.canSubmitExternalCommands && mergedHost.allowEdit && hoststatus.isInMonitoring"
                               :title-link-class="linkClass()">
                            <template #title>
                                <i class="fa-solid fa-terminal"></i>
                                {{ $t('actions') }}
                            </template>

                            <host-external-commands :hostuuid="mergedHost.uuid" :hostid="mergedHost.id"
                                                    :hoststatus="hoststatus.current_state"
                                                    :status="hoststatus" :services="services" :downtime="downtime"
                                                    @refresh-event="refreshData()">

                            </host-external-commands>
                        </b-tab>

                    </b-tabs>
                </b-card>
            </b-list-group>
        </b-container>
    </div>
</template>

<script>
import HostExternalCommands from "./externalcommands/HostExternalCommands";
import SlidingPagination from 'vue-sliding-pagination'
import Priority from "../utils/Priority";
import router from "../../router";
import Servicestatus from "../utils/Servicestatus";
import DateSwitch from "../utils/DateSwitch";

export default {
    name: "HostsDetails",
    components: {DateSwitch, Servicestatus, Priority, HostExternalCommands, SlidingPagination},
    data(){
        return {
            mainHeaderBgClasses: '',
            mainHeaderRefreshBtnClasses: '',
            hasParentHostProblems: false,
            parenthosts: [],
            parentHostProblems: {},
            loading: true,
            hostDetails: [],
            mergedHost: [],
            hoststatus: [],
            acknowledgement: [],
            downtime: [],
            checkPeriod: [],
            notifyPeriod: [],
            services: [],
            services_paging: {
                page: 1,
                pageCount: 1,
            },
            hostId: Number,
            breadcrumps: [
                {
                    text: 'Hosts',
                    to: {name: 'hostsIndex'}
                },
                {
                    text: 'Host Details',
                    active: true
                }
            ]
        };
    },
    methods: {
        linkClass: function(){
            return ['lesspadding'];
        },
        getData(){
            let loader = this.$loading.show();
            this.loading = true;
            this.hostId = this.$route.params.id;
            axios.post('/hosts/details.json', null, {
                params: {
                    id: this.hostId,
                }
            })
                .then(response => {
                    this.loading = false;
                        this.hostDetails = response.data.hostDetails ?? [];
                        this.mergedHost = response.data.hostDetails.mergedHost ?? [];
                        this.hoststatus = response.data.hostDetails.hoststatus ?? [];
                        this.checkPeriod = response.data.hostDetails.checkPeriod ?? [];
                        this.notifyPeriod = response.data.hostDetails.checkPeriod ?? [];
                        this.parenthosts = response.data.hostDetails.parenthosts ?? [];
                        this.parentHoststatus = response.data.hostDetails.parentHostStatus ?? [];
                        if('acknowledgement' in response.data.hostDetails){
                            this.acknowledgement = response.data.hostDetails.acknowledgement;
                        }
                        if('downtime' in response.data.hostDetails){
                            this.downtime = response.data.hostDetails.downtime;
                        }
                        if(this.services_paging.page === 1){
                            this.getServiceData(this.services_paging.page);
                        }

                        this.mainHeaderBgClasses = this.getHeaderBgClass();
                        this.mainHeaderRefreshBtnClasses = this.getHeaderRefreshBtnClass();
                        this.buildParentHostProblems();
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                })
                .finally(() => {
                    loader.hide();
                });
        },
        getServiceData(page){
            let loader = this.$loading.show();
            axios.post('/services/index.json', {
                filter: {
                    filter: {
                        hostsId: [this.hostId]
                    }
                },
                page: page
            }, {
                params: {}
            })
                .then(response => {
                    if(response.status == 200){
                        this.services = response.data.all_services['all_services'] ?? [];
                        this.services_paging = response.data.all_services['paging'] ?? {
                            page: 1,
                            pageCount: 1,
                        };
                    }else{
                        this.$notify({
                            type: 'error',
                            title: this.$t('no-refresh'),
                        });
                    }
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                })
                .finally(() => {
                    loader.hide();
                });
        },
        refreshData(){
            this.getData();
            this.$refs.tab1.activate();
        },
        getHeaderBgClass(){
            if(!this.hoststatus.isInMonitoring){
                return 'bg-primary shadow-lg-primary text-white';
            }

            switch(this.hoststatus.currentState){
                case 0:
                    return 'bg-up shadow-lg-up text-white';

                case 1:
                    return 'bg-down shadow-lg-down text-white';

                default:
                    return 'bg-unreachable shadow-lg-unreachable text-dark';
            }
        },
        getHeaderRefreshBtnClass(){
            if(!this.hoststatus.isInMonitoring){
                return 'btn-primary';
            }

            switch(this.hoststatus.currentState){
                case 0:
                    return 'btn-success';

                case 1:
                    return 'btn-danger';

                default:
                    return 'btn-secundary';
            }
        },
        buildParentHostProblems(){
            this.hasParentHostProblems = false;
            this.parentHostProblems = {};
            for(var key in this.parenthosts){
                let parentHostUuid = this.parenthosts[key].uuid;
                if(this.parentHoststatus.hasOwnProperty(parentHostUuid)){
                    if(this.parentHoststatus[parentHostUuid].currentState > 0){
                        this.parentHostProblems[parentHostUuid] = {
                            id: this.parenthosts[key].id,
                            name: this.parenthosts[key].name,
                            state: this.parentHoststatus[parentHostUuid].currentState
                        };
                        this.hasParentHostProblems = true;
                    }
                }
            }
        },
    },
    watch: {
        // Hacky? Otherwise Vue will not reload the data if a state links to itself
        // /hosts/details/4 links to /hosts/details/10
        '$route.params.id': {
            handler: function(){
                this.getData();
            },
            //deep: true,
            //immediate: true
        }
    },
    mounted(){
        this.getData();
    },

}
</script>

<style scoped>

</style>
