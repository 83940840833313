<template>
    <div>
            <b-list-group-item>
                <b-form>
                    <b-form-group id="input-group-1-passivecheck" label="Status:"
                                  label-for="input-passive">
                        <b-form-select
                            id="input-passive"
                            v-model="checkForm.state"
                            :options="checkForm.options"
                            required
                        ></b-form-select>
                    </b-form-group>
                    <b-form-group
                        id="input-group-1-passiveCheck"
                        :label="$t('forms.message')"
                        label-for="textarea-passive">
                        <b-form-textarea v-model="$v.checkForm.comment.$model"
                                         :class="{ 'is-invalid': $v.checkForm.$error }"
                                         id="textarea-passive"
                                         placeholder="Comment for passive check"
                                         rows="3"
                                         max-rows="6"
                        ></b-form-textarea>
                        <div class="invalid-feedback" v-if="!$v.checkForm.comment.required">{{ $t('Field is required') }}
                        </div>
                    </b-form-group>
                    <b-form-group id="input-group">
                        <b-form-checkbox
                            v-model="checkForm.forceHardstate"
                            id="checkboxes-custom-notification"
                            name="checkboxes-custom-notification"
                        >
                            Force to hard State
                        </b-form-checkbox>
                    </b-form-group>
                    <b-button variant="success" @click="passiveCheck" class="w-100">{{ $t('forms.send-command') }}
                    </b-button>
                </b-form>
            </b-list-group-item>
    </div>
</template>

<script>
import {validationMixin} from "vuelidate"
import {required} from "vuelidate/lib/validators"

export default {
    name: "HostPassiveCheck",
    mixins: [validationMixin],
    props: [
        'hostuuid',
    ],
    data(){
        return {
            checkForm: {
                state: 0,
                options: [
                    {value: 0, text: 'up'},
                    {value: 1, text: 'down'},
                    {value: 2, text: 'unreachable'},
                ],
                comment: 'Passive check',
                forceHardstate: false,
            },
        }
    },
    validations: {
        checkForm: {
            comment: {
                required,
            }
        }
    },
    methods: {
        passiveCheck(){
            this.$v.checkForm.$touch();
            if(this.$v.checkForm.$anyError){
                return;
            }
            let externalCommands = [];
            externalCommands.push({
                command: 'PROCESS_HOST_CHECK_RESULT',
                hostUuid: this.hostuuid,
                status_code: this.checkForm.state,
                plugin_output: this.checkForm.comment,
                long_output: '',
                //forceHardstate: parseInt(this.checkForm.forceHardstate, 10),

            });
            if(this.checkForm.forceHardstate){
                for ( let i=0; i<9; i++ ) {
                    externalCommands.push({
                        command: 'PROCESS_HOST_CHECK_RESULT',
                        hostUuid: this.hostuuid,
                        status_code:  this.checkForm.state,
                        plugin_output:  this.checkForm.comment,
                        long_output: ''
                    });
                }
            }
            this.sendCommand(externalCommands);
        },
        sendCommand: function(externalCommands){
            axios.post('/hosts/external_commands.json', externalCommands)
                .then(response => {
                    this.$notify({
                        type: 'success',
                        title: '',
                        text: this.$t('Command successfully sent'),
                        duration: 7000
                    });
                    this.$emit('refresh', 'togglePassiveColapse');
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        },
    }
}
</script>

<style scoped>

</style>
