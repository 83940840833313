<template>
    <div>
        <b-alert
            :show="successCountDown"
            dismissible
            variant="success"
            @dismissed="successCountDown=0"
            @dismiss-count-down="countDownChanged">
            <p>{{ $t('The scanresult will be copied in') }} {{ successCountDown }} {{$t('seconds...')}}</p>
            <b-progress
                variant="success"
                :max="successSecs"
                :value="successCountDown"
                height="4px"
            ></b-progress>
        </b-alert>
        <label for="qrtextarea">{{ $t('Last result') }}:</label>
        <b-form-textarea
            id="qrtextarea"
            v-model="result"
            :placeholder="$t('scan something...')"
            rows="1"
            max-rows="6"
        ></b-form-textarea>
        <div v-if="!isValid" class="validation-failure">
            the scanned key is not valid!
        </div>
        <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
            <div v-show="showScanConfirmation" class="scan-confirmation">
                <img src="/img/icons8-scan-64.png" alt="Checkmark" width="128px" />
            </div>
        </qrcode-stream>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    name: "QrcodeScanner",
    components: { QrcodeStream },

    data () {
        return {
            camera: 'auto',
            result: null,
            isValid: true,
            showScanConfirmation: false,
            successSecs: 3,
            successCountDown: 0,
            showDismissibleAlert: false
        }
    },

    methods: {

        async onInit (promise) {
            try {
                await promise
            } catch (e) {
                console.error(e)
            } finally {
                this.showScanConfirmation = this.camera === "off"
            }
        },

        onDecode(content){
            this.result = content
            // await this.timeout(3000)
            this.isValid = content.length === 160
            this.pause()
            if(this.isValid){
                this.showAlert()
                setTimeout(this.send, 3500)
            } else{
                setTimeout(this.unpause, 1000)
            }
        },
        send(){
            this.$emit('qrevent', this.result);
        },

        unpause () {
            this.camera = 'auto'
        },

        pause () {
            this.camera = 'off'
        },

        countDownChanged(successCountDown) {
            this.successCountDown = successCountDown
        },

        showAlert() {
            this.successCountDown = this.successSecs
        },

        timeout (ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        }
    }
}
</script>

<style scoped>

.validation-success {
    color: green;
}
.validation-failure {
    color: red;
}

.scan-confirmation {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, .8);

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
</style>
