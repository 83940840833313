<template>
    <div>
        <div v-if="this.getNotificationsEnabled">
            <b-form-group
                :description="$t('forms.notification-disable-description')">
                <label class="typo__label">{{ $t('select') }}</label>
                <multiselect v-model="disableNotificationsTypeSelected"
                             track-by="value"
                             :close-on-select="true"
                             :show-labels="false"
                             label="text"
                             placeholder="Select one"
                             :options="disableNotificationsTypeOptions"
                             :searchable="false"
                             :allow-empty="false">
                </multiselect>
            </b-form-group>

            <b-button variant="success" class="w-100" v-on:click="sendDisableNotificationsCommand">{{ $t('action-items.notifications-disable') }}</b-button>
        </div>
        <div v-if="!this.getNotificationsEnabled">
            <b-form-group
                :description="$t('forms.notification-enable-description')">
                <label class="typo__label">{{ $t('select') }}</label>
                <multiselect v-model="enableNotificationsTypeSelected"
                             track-by="value"
                             :close-on-select="true"
                             :show-labels="false"
                             label="text"
                             placeholder="Select one"
                             :options="enableNotificationsTypeOptions"
                             :searchable="false"
                             :allow-empty="false">
                </multiselect>
            </b-form-group>
            <b-button variant="success" class="w-100" v-on:click="sendEnableNotificationsCommand">{{ $t('action-items.notifications-enable') }}</b-button>
        </div>
    </div>
</template>

<script>
/*
DISABLE_HOST_NOTIFICATIONS
DISABLE_HOST_SVC_NOTIFICATIONS
ENABLE_HOST_NOTIFICATIONS
ENABLE_HOST_SVC_NOTIFICATIONS
 */
import Multiselect from "vue-multiselect";

export default {
    name: "HostNotifications",
    components: {Multiselect},
    data(){
        return {
            disableNotificationsTypeSelected: {
                value: 'DISABLE_HOST_NOTIFICATIONS',
                text: this.$t('hosts.individual')
            },
            disableNotificationsTypeOptions: [
                {
                    value: 'DISABLE_HOST_NOTIFICATIONS',
                    text: this.$t('hosts.individual')
                },
                {
                    value: 'DISABLE_HOST_SVC_NOTIFICATIONS',
                    text: this.$t('hosts.including-services')
                }
            ],
            enableNotificationsTypeSelected: {
                value: 'ENABLE_HOST_NOTIFICATIONS',
                text: this.$t('hosts.individual')
            },
            enableNotificationsTypeOptions: [
                {
                    value: 'ENABLE_HOST_NOTIFICATIONS',
                    text: this.$t('hosts.individual')
                },
                {
                    value: 'ENABLE_HOST_SVC_NOTIFICATIONS',
                    text: this.$t('hosts.including-services')
                }
            ],
        }
    },
    props: [
        'hostuuid',
        'notificationsEnabled'
    ],
    methods: {
        sendDisableNotificationsCommand: function(){
            let externalCommand = [
                {
                    command: 'DISABLE_HOST_NOTIFICATIONS',
                    hostUuid: this.getUuid,
                }
            ];

            if(this.disableNotificationsTypeSelected.value === 'DISABLE_HOST_SVC_NOTIFICATIONS'){
                // we need to trigger the SCHEDULE_HOST_DOWNTIME command as the SCHEDULE_HOST_SVC_DOWNTIME sets all
                // services from the host into a downtime except the host itself
                externalCommand.push({
                    command: 'DISABLE_HOST_SVC_NOTIFICATIONS',
                    hostUuid: this.getUuid,
                });
            }

           // externalCommand = JSON.stringify(Object.assign({}, externalCommand));

            this.sendCommand(externalCommand);

        },
        sendEnableNotificationsCommand: function(){
            let externalCommand = [
                {
                    command: 'ENABLE_HOST_NOTIFICATIONS',
                    hostUuid: this.getUuid,
                }
            ];

            if(this.enableNotificationsTypeSelected.value === 'ENABLE_HOST_SVC_NOTIFICATIONS'){
                externalCommand.push({
                    command: 'ENABLE_HOST_SVC_NOTIFICATIONS',
                    hostUuid: this.getUuid,
                });
            }

           // externalCommand = JSON.stringify(Object.assign({}, externalCommand));

            this.sendCommand(externalCommand);
        },
        sendCommand: function(externalCommand){
          /*  axios.post('/hosts/external_commands.json', null, {
                params: {
                    externalCommand: externalCommand,
                }
            }) */
            axios.post('/hosts/external_commands.json', externalCommand)
                .then(response => {
                        this.$notify({
                            type: 'success',
                            title: '',
                            text: this.$t('Command successfully sent'),
                            duration: 7000
                        });
                    this.$emit('refresh', 'notification')
                })
                .catch(error => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                    });
                });
        },
    },
    computed: {
        getUuid: function(){
            return this.hostuuid;
        },
        getNotificationsEnabled: function(){
            return this.notificationsEnabled;
        }
    }
}
</script>

<style scoped>

</style>
