<template>
    <div class="w-100 min-vh-100 login-screen" ref="loader" v-bind:style="{ 'background-image': 'url(' + bg_image + ')' }">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto login-form-1 min-vh-100 login-form-bg">
                    <div class="col-12 text-center" style="padding-top: 80px;">
                        <img class="img-fluid" :src="logo" style="max-height: 230px;"/>
                    </div>
                    <h3>Login</h3>
                    <b-form @submit.prevent="login" method="post" autocomplete='on'>
                        <b-form-group>
                            <input id="username" v-model="base_url" type="text" class="form-control" name="url"
                                   :placeholder="$t('login-server')"
                                   :readonly="predefined_server_url"
                                   value="" ref="server" required/>
                        </b-form-group>
                        <b-form-group>
                            <input id="key" v-model="api_key" type="password" class="form-control" name="key"
                                   :placeholder="$t('login-key')"
                                   ref="key"
                                    required/>
                        </b-form-group>
                        <div class="d-flex w-100 justify-content-between" v-if="scanner">
                            <b-button pill size="sm" variant="dark" style="width:100%!important;" v-b-modal.qrModal>
                                {{ $t('Qrcode-Scanner for  Apikey') }}
                            </b-button>
                        </div>
                        <div class="d-flex w-100 justify-content-between" style="color:red;" v-if="!scanner">
                            Qrcode-scanner only allowed with https!
                        </div>

                        <div class="d-flex w-100 justify-content-between" style="padding-top:10px;">
                            <b-form-checkbox
                                id="rememberMe"
                                v-model="remember_me" name="remember_me">
                                Remember me
                            </b-form-checkbox>
                            <!--<button class="btn btn-primary" type="button" value="Login" @click="login">-->
                            <button class="btn btn-primary" type="submit">
                            <span>
                                  <i class="fa fa-spinner fa-spin" v-show="loading"></i>
                            </span>
                                Login
                            </button>
                        </div>

                    </b-form>
                </div>
            </div>

            <!-- qr-modal-->
            <div>
                <b-modal
                    id="qrModal"
                    ref="qrModal"
                    title="QrScanner"
                    header-bg-variant="primary"
                    header-text-variant="white">
                    <qrcode-scanner @qrevent="getQr"/>
                    <template #modal-footer="{ ok, cancel, hide }">
                        <!--<b-button class="mr-auto" size="sm" variant="primary" @click="ok();getQr()">
                            <i class="fa-solid fa-copy"></i>
                            Copy apikey
                        </b-button>-->
                        <b-button size="sm" variant="secondary" @click="cancel()">
                            {{ $t('Cancel') }}
                        </b-button>
                    </template>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import QrcodeScanner from './QrcodeScanner.vue'
export default {
    name: "Login",
    components: {
        QrcodeScanner
    },
    data(){
        return {
            scanner: true,
            creds: '',
            base_url: '',
            api_key: null,
            remember_me: true,
            logo: '',
            bg_image: '',
            loading: false,
            predefined_server_url: false
        };
    },
    created(){


    },
    mounted(){

        axios.post('/users/getSystemConfig.json')
            .then(response => {
                this.logo = response.data.loginLogo;
                this.bg_image = response.data.loginBg;
                if(response.data.serverUrl.length > 0){
                    // server-url is defined in interface.yml
                    this.predefined_server_url = true;
                    this.base_url = response.data.serverUrl;
                }
            })
            .catch(error => {
                console.log('Could not fetch logo');
            });
        setTimeout(this.focusServerInput, 500)
       if (location.protocol !== "https:"){
            this.scanner = false

            let needle = window.location.href
            if(needle.includes("localhost")){
                this.scanner = true
            }
            if(needle.includes("127.0.0.1")){
                this.scanner = true
            }
        }
    },
    methods: {
        focusServerInput(){
            //this.$refs['server'].focus()
            document.getElementById("username").focus()
        },
        focusKeyInput(){
            //this.$refs['server'].focus()
            document.getElementById("key").focus()
        },
        login(){
            this.loading = true
            let loader = this.$loading.show();
            axios.post('/users/login', {
                url: this.base_url,
                key: this.api_key,
                remember_me: this.remember_me
            })
                .then(response => {
                    loader.hide()
                    if(response.status === 200 && response.data.loggedIn === true){
                        localStorage.setItem('status', response.data.loggedIn);
                        this.$store.commit('setState', response.data.loggedIn);
                        localStorage.setItem('email', response.data.email);
                        this.$store.commit('setEmail', response.data.email);
                        localStorage.setItem('user', response.data.user);
                        this.$store.commit('setUser', response.data.user);
                        localStorage.setItem('user_timezone', response.data.user_timezone);
                        this.$store.commit('setUserTimezone', response.data.user_timezone);

                        //set logout-button in navbar
                        this.$root.$emit('login-event');
                        this.$router.push({name: 'dashboardsIndex'});
                        console.log(window.location.href)
                        window.location.replace(window.location.href);
                        if (window.PasswordCredential) {
                            console.log("Credential save possible")
                           /* const cred = new PasswordCredential({
                                id,
                                password,
                                name,
                                iconURL,
                            }); */
                        }
                    }else if(response.status === 500){
                        this.$notify({
                            type: 'error',
                            title: 'Server error',
                        })
                    }else{
                        if(response.data.type === 'error'){
                            this.$notify({
                                type: 'error',
                                title: response.data.message,
                            })
                        }
                    }
                    this.loading = false
                })
                .catch(error => {
                    loader.hide()
                    this.$notify({
                        type: 'error',
                        title: 'login not possible',
                        text: error,
                    })
                    this.running = false
                });
        },
        getQr(qrcode){
           // document.getElementById("key").value = qrcode;
           // this.$refs.key.focus()
            setTimeout(this.focusKeyInput,100)
           /* window.dispatchEvent(new KeyboardEvent('keydown', {
                'key': 'b'
            })) */
            this.$refs.qrModal.hide()
           this.api_key = qrcode
            //navigator.clipboard.writeText(qrcode);
            //this.focusKeyInput()
            //

            setTimeout(this.focusKeyInput,500)
        }
    },
    watch: {}
}
</script>

<style scoped>

</style>
